import React from 'react';

const GoogleMap = () => {
  return (
    <div className="row">
      {/* Contenedor responsivo */}
      <div className="embed-responsive embed-responsive-16by9">
        {/* Mapa de Google */}
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d475.5182673617357!2d-98.5824342!3d17.5482276!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c923c599a4f9b9%3A0x71b24f05f2a41ae4!2sNotar%C3%ADa%20P%C3%BAblica%20N%C3%BAmero%201%20de%20Tlapa%20de%20Comonfort!5e0!3m2!1ses-419!2smx!4v1716476317002!5m2!1ses-419!2smx" 
          width="600" 
          height="400" 
          className="embed-responsive-item"
          style={{ border: '0' }} 
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default GoogleMap;
